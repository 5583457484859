import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import Img from "gatsby-image";
import * as El from "./styles";

const HomeBox = ({ image, data }) => {
	const { title, description, button, slug, color } = data;
	return (
		<El.StyledCol xs={12} sm={6}>
			<El.StyledFigure>
				<figcaption>
					<h2 style={{ background: color }}>{title}</h2>
				</figcaption>
				<Link to={`/${slug}`}>
					<Img
						fluid={image}
						alt=""
						// imgStyle={{ objectPosition: "0 0" }}
						// style={{ width: "100%" }}
					/>
				</Link>
			</El.StyledFigure>
			<p>{description}</p>
			{button.includes("payment") ? (
				<El.StyledLink to="/payment">{`${button} >`}</El.StyledLink>
			) : (
				<El.StyledLink to="/our-method">{`${button} >`}</El.StyledLink>
			)}
		</El.StyledCol>
	);
};

HomeBox.propTypes = {
	image: PropTypes.shape({}),
	data: PropTypes.shape({})
};

export default HomeBox;
