import styled from "styled-components";
import Link from "gatsby-link";
import { Col } from "react-styled-flexboxgrid";

import media from "../../tools/mediaQueries";

export const StyledCol = styled(Col)`
	margin-bottom: 35px;
	text-align: center;
	font-family: "Montserrat";
	font-weight: 700;
	font-size: 14px;
	${media.sm`
    font-size: 16px;
  `};

	p {
		margin: 10px 6%;
		line-height: 22px;
		color: #5c5c5c;
	}
`;

export const StyledFigure = styled.figure`
	position: relative;
	overflow: hidden;
	min-width: 100%;
	height: auto;
	text-align: center;
	cursor: pointer;
	margin: 0;
	margin-bottom: 10px;
	transition: 0.35s;

	&:hover img {
		transform: perspective(1000px) translate3d(0, 0, 21px);
		transition: 0.35s;
	}

	h2 {
		font-family: "Raleway";
		text-transform: uppercase;
		position: relative;
		padding: 0.25em;
		margin: 0;
		line-height: 46px;
		font-size: 18px;
		color: #fff;
		font-weight: 500;
	}
`;

export const StyledLink = styled(Link)`
	color: #567a2a;
	text-decoration: none;
	margin-bottom: 10px;
	display: block;
`;
