import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import Layout from "../layouts";
import makeKey from "../tools/makeKey";
import media from "../tools/mediaQueries";

import Hero from "../components/Hero";
import HomeBox from "../components/HomeBox";

const IndexPage = ({ data }) => {
	const { list } = data.list.edges[0].node;
	const { boxes } = data.boxes.edges[0].node;
	return (
		<Layout>
			<Grid>
				<Helmet title="STERN & STERN" />
				<Row style={{ background: "#fff" }}>
					<Hero
						image={data.hero.childImageSharp.fluid}
						title="GET SERIOUS"
						lead="HIRE AN ATTORNEY TO COLLECT YOUR DEBTS"
					/>
					<BulletPoints xs={12}>
						<h2>Why hire Stern & Stern, P.C. to collect your debts?</h2>
						<ul>
							{list.map((item, i) => {
								const id = makeKey(item, i);
								return (
									<li key={id}>
										<p>{item}</p>
									</li>
								);
							})}
						</ul>
						<p className="about">
							Because Stern & Stern, P.C. possesses the means, access and legal
							resources in-house to enforce your rights as a creditor to the
							full extent of the law. Combined with our state-of-the-art
							technology and extensive experience, our collections-dedicated
							capabilities result in a higher potential for success in
							recovering a greater amount of debt.{" "}
						</p>
						<h3>NO COLLECTION, NO FEE</h3>
						<h3>“We collect on the account or our services are free!”</h3>
					</BulletPoints>
				</Row>
				<Row style={{ background: "#fff" }}>
					{boxes.map((item) => (
						<HomeBox
							key={item.slug}
							data={item}
							image={data[item.image].childImageSharp.fluid}
						/>
					))}
				</Row>
			</Grid>
		</Layout>
	);
};

export const query = graphql`
	query HomePage {
		list: allHomeJson {
			edges {
				node {
					list
				}
			}
		}
		boxes: allHomeJson {
			edges {
				node {
					boxes {
						title
						image
						description
						button
						slug
						color
					}
				}
			}
		}
		hero: file(relativePath: { eq: "home_hero.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1200) {
					...GatsbyImageSharpFluid
				}
			}
		}
		method: file(relativePath: { eq: "method.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1200) {
					...GatsbyImageSharpFluid
				}
			}
		}
		secure: file(relativePath: { eq: "secure.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 1200) {
					...GatsbyImageSharpFluid
				}
			}
		}
		get: file(relativePath: { eq: "get.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 287) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;

IndexPage.propTypes = {
	data: PropTypes.shape({}).isRequired
};

export default IndexPage;

const BulletPoints = styled(Col)`
	text-align: left;
	font-family: "Montserrat";

	${media.sm`
    text-align: center;
  `};

	h2 {
		font-family: "Raleway";
		margin: 0px 0 20px;
		line-height: 32px;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 1.6875rem;

		${media.sm`
      font-size: 30px;
    `};
	}

	ul {
		padding: 0;
		margin-bottom: 1.25rem;
	}

	li {
		list-style: disc;
		list-style-position: inside;
		font-size: 25px;
		font-weight: 700;
		color: #a85701;
		line-height: 30px;
		margin: 0;

		${media.sm`
      line-height: 36px;
    `};

		${media.md`
      font-size: 35px;
    `};

		p {
			font-size: 16px;
			font-weight: 400;
			color: rgba(0, 0, 0, 1);
			line-height: 26px;
			margin: 0;

			${media.sm`
        font-size: 24px;
        font-weight: 700;
        color: #000;
        line-height: 36px;
      `};
		}
	}

	p.about {
		line-height: 26px;
		font-size: 18px;
		text-align: left;
		padding: 0 0px;
		margin-bottom: 15px;

		${media.sm`
      line-height: 30px;
      font-size: 24px;
      text-align: justify;
      padding: 0 7%;
      margin-bottom: 15px;
    `};
	}

	h3 {
		text-align: center;
		color: #4b7737;
		font-weight: 700;
		font-size: 1.375rem;

		&:last-child {
			font-style: italic;
			margin-bottom: 20px;
		}

		${media.sm`
      font-size: 24px;
    `};
	}
`;
